<template>
  <div class="container">
    <!-- 顶部包含下载按钮、应用名称和 Logo -->
    <div class="header">
      <div class="app-info">
        <img class="logo" src="../assets/logo.jpg" />
        <span class="appname">Burket</span>
      </div>
      <a class="download-btn" @click="downloadClick">下载应用/دەتالدى تۇسىرۋ</a>
    </div>

    <!-- 横幅图片 -->
    <div class="banner-container">
      <div class="mobile-banner">
        <img src="../assets/mobile_banner.png" class="banner-img" />
        <span class="banner-name">تەلەفون</span>
      </div>
      <div class="tv-banner" style="margin-right: 10px;">
        <img src="../assets/tv_banner.png" class="banner-img" />
        <span class="banner-name">电视版/تەلەۆيزور نۇسقاسى</span>
      </div>
    </div>

    <!-- 电影推荐部分 -->
    <section class="section">
      <h3 class="section-title">ۇسنىلعان كينولار</h3>
      <ul class="media-list">
        <li v-for="(item, index) in dianyinglist" :key="index" class="media-item">
          <img class="media-cover" :src="item.img" @click="dianyingClick(index)" />
          <span class="media-name">{{ item.name }}</span>
        </li>
      </ul>
    </section>

    <!-- 电视剧推荐部分 -->
    <section class="section">
      <h3 class="section-title">ۇسنىلعان سەريالدار</h3>
      <ul class="media-list">
        <li v-for="(item, index) in dianshijulist" :key="index" class="media-item">
          <img class="media-cover" :src="item.img" @click="dianshijuClick(index)" />
          <span class="media-name">{{ item.name }}</span>
        </li>
      </ul>
    </section>

    <!-- 简短描述和公司名称 -->
    <div class="footer-text">
      بۇركىت دەتالىن تۇسرىپ الۋان تۇرلى مازمۇنداردى تاماشالاپ كەرەمەت يقۋاتتاردى پايدالانڭىز
      <br />
      <span class="company-name">松贺拉网络科技 &copy; 2024 Burket</span>
      <br />
      <!-- 备案信息 -->
      <div class="icp-info">
        <img class="icp-icon" src="../assets/gongan.png" alt="备案图标" />
        <span>浙ICP备2021036215号</span>
      </div>
    </div>

  </div>
</template>

<script>
import { getHome } from "../api";

export default {
  name: "container",
  data() {
    return {
      dianyinglist: [],
      dianshijulist: [],
    };
  },
  methods: {
    dianyingClick(index) {
      this.$router.push({
        path: "/VideoPlayer",
        query: { id: this.dianyinglist[index].id },
      });
    },
    dianshijuClick(index) {
      this.$router.push({
        path: "/VideoPlayer",
        query: { id: this.dianshijulist[index].id },
      });
    },
    downloadClick() {
      alert('ۇنحاتتىڭ ىشىندە باسقا تۇسىرۋگە كەلمەيتىن سەبەپتى،وڭ جاق جوعارداعى ۇش نۇكتەنى باسىپ 在浏览器打开 دەگەندى باسىپ باسقا تور شولۋدان اشىپ تۇسىرەسىز\n 因无法在微信中下载文件，请点击右上角三个点，点击 在浏览器打开 进行下载 ');

    }


  },
  created() {
    getHome().then((ret) => {
      this.dianyinglist = ret.data["dianying"];
      this.dianshijulist = ret.data["dianshiju"];
    });
  },
};
</script>

<style scoped>
/* 总容器 */
.container {
  background: linear-gradient(135deg, #0d0d0d, #1a1a1a);
  color: #fff;
  padding: 20px;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 顶部布局 */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 20px;
}

.app-info {
  display: flex;
  align-items: center;
}

.logo {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.appname {
  font-size: 22px;
  margin-right: 10px;
  font-weight: bold;
  color: #ffffff;
  /* 白色应用名称 */
}

.download-btn {
  background-color: #ff7f50;
  color: #fff;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

/* 横幅图片容器 */
.banner-container {
  display: flex;
  margin-bottom: 20px;
}

/* 手机版横幅 */
.mobile-banner {
  width: 21%;
  text-align: center;
}

.tv-banner {
  width: 79%;
  text-align: center;
}

.banner-img {
  width: 100%;
  border-radius: 10px;
}

.banner-name {
  margin-top: 5px;
  font-size: 14px;
  color: #ffcc00;
  /* 横幅名称颜色 */
}

/* 内容部分 */
.section {
  margin-top: 15px;
}

.section-title {
  font-size: 16px;
  margin-bottom: 10px;
  color: #ffcc00;
}

/* 列表布局 */
.media-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.media-item {
  width: 30%;
  text-align: center;
}

.media-cover {
  width: 100%;
  aspect-ratio: 2 / 3;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s;
}

.media-cover:hover {
  transform: scale(1.05);
}

.media-name {
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 底部描述和公司名称 */
.footer-text {
  margin-top: 30px;
  font-size: 14px;
  color: #999;
  text-align: center;
  direction: ltr;

}

.company-name {
  display: block;
  margin-top: 10px;
  font-size: 14px;
  color: #777;
  font-weight: bold;
}

.icp-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 12px;
}

.icp-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

</style>
