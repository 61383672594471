import Vue from 'vue'
import App from './App.vue'
import router from './router.js'

import weui from 'weui.js'
import 'weui.js'
import './static/css/weui.css'
import './static/css/weuix.css'

import VueCoreVideoPlayer from 'vue-core-video-player'
import axios from 'axios'

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.use(VueCoreVideoPlayer)

Vue.prototype.$weui = weui
Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  axios,
  router,
  render: h => h(App),
}).$mount('#app')

