<template>
    <div class="container">
        <!-- Logo and App Name -->
        <div class="header">
            <img class="logo" src="../assets/logo.jpg" alt="Burket Logo" />
            <h1 class="appname">Burket</h1>
        </div>

        <!-- Download Section -->
        <div class="download-section">
            <!-- Android App Download -->
            <div class="download-box">
                <img class="icon" src="../assets/phone.svg" alt="Phone Icon" />
                <a href="https://www.burket.cn/burket.apk" class="btn green-btn">下载安卓版/تەلەفون نۇسقاسىن تۇسىرۋ </a>
            </div>

            <!-- TV App Download -->
            <div class="download-box">
                <img class="icon" src="../assets/tv.svg" alt="TV Icon" />
                <a href="https://www.burket.cn/burkettv.apk" class="btn green-btn">下载电视版/تەلەۆيزور نۇسقاسىن تۇسىرۋ </a>
            </div>

            <!-- Windows App Download -->
            <div class="download-box">
                <img class="icon" src="../assets/windows.svg" alt="Windows Icon" />
                <a href="https://www.burket.cn/burket.exe" class="btn green-btn">Windows كوميوتەر نۇسقاسىن تۇسىرۋ</a>
            </div>

            <!-- Mac App Download -->
            <div class="download-box">
                <img class="icon" src="../assets/mac.svg" alt="Mac Icon" />
                <a href="https://www.burket.cn/Burket.dmg" class="btn green-btn">Mac كوميوتەر نۇسقاسىن تۇسىرۋ</a>
            </div>
        </div>

        <!-- Footer -->
        <!-- Footer -->
        <div class="footer">
            <p>松贺拉网络科技 &copy; 2024 Burket</p>
            <p>
                <img class="police-logo" src="../assets/gongan.png" alt="警徽" />
                浙ICP备2021036215号
            </p>
        </div>

    </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* Main container styling */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url('../assets/playbg.png');
    background-size: cover;
    background-position: center;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
}

/* Header styling */
.header {
    text-align: center;
    margin-bottom: 20px;
}

.logo {
    width: 60px;
    height: 60px;
    border-radius: 5%;
    margin-bottom: 10px;
}

.appname {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
}

/* Download section */
.download-section {
    width: 100%;
    max-width: 350px;
}

.download-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    text-align: center;
}

.icon {
    width: 40px;
    height: auto;
    margin-bottom: 10px;
}

/* Button styling */
.btn {
    display: inline-block;
    width: 100%;
    padding: 14px 0;
    border-radius: 8px;
    font-size: 0.6rem;
    font-weight: bold;
    color: #ffffff;
    text-decoration: none;
    transition: background-color 0.3s;
    text-align: center;
    background-color: #4caf50;
    margin-top: 5px;
}

.btn:hover {
    opacity: 0.9;
}

/* Footer styling */
.footer {
    margin-top: 40px;
    font-size: 0.8rem;
    color: #cccccc;
    direction: ltr;

}

.police-logo {
    width: 22px;
    height: 22px;
    vertical-align: middle;
    margin-right: 5px;
}

</style>
