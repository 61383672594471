<template>
  <div class="container page-hd">
    <div class="weui-flex">
      <div class="weui-flex__item">
        <a class="weui-btn bg-orange wide-button" v-on:click="downloadClick()">下载应用/دەتالدى تۇسىرۋ</a>
      </div>
      <div class="weui-flex__item">
        <div class="logobox" v-on:click="homeClick()">
          <span class="appname">باس بەت</span>
          <img class="logo" src="../assets/logo.jpg" />
        </div>
      </div>
    </div>

    <div id="app">
      <div class="player-container">
        <vue-core-video-player :src="videoSource" :cover="cover" :title="title" autoplay />
      </div>
    </div>

    <h5 class="title">{{ title }}</h5>
    <p class="page-hd-desc">{{ playnum }} ىرەت كورىلدى</p>
    <ul class="numberlist">
      <li class="videonum" v-for="(item, index) in videolist" :key="index" v-on:click="numClick(index)">
        {{ index + 1 }}
      </li>
    </ul>

    <div class="article">
      <img style="border-radius: 10px;" class="avator" v-if="avator.indexOf('http') != -1" :src="avator" />
      <img style="border-radius: 10px;" class="avator" v-if="avator.indexOf('http') == -1"
        :src="'https://app.burket.cn/upload/' + avator" />
      <p class="remark">{{ article }}</p>
    </div>

    <!-- 横幅图片 -->
    <div class="banner-container">
      <div class="mobile-banner">
        <img src="../assets/mobile_banner.png" class="banner-img" />
        <span class="banner-name">تەلەفون</span>
      </div>
      <div class="tv-banner" style="margin-right: 10px;">
        <img src="../assets/tv_banner.png" class="banner-img" />
        <span class="banner-name">电视版/تەلەۆيزور نۇسقاسى</span>
      </div>
    </div>

    <!-- 简短描述和公司名称 -->
    <div class="footer-text">
      بۇركىت دەتالىن تۇسرىپ الۋان تۇرلى مازمۇنداردى تاماشالاپ كەرەمەت يقۋاتتاردى پايدالانڭىز
      <br />
      <span class="company-name">松贺拉网络科技 &copy; 2024 Burket</span>
      <br />
      <!-- 备案信息 -->
      <div class="icp-info">
        <img class="icp-icon" src="../assets/gongan.png" alt="备案图标" />
        <span>浙ICP备2021036215号</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAll, getRedictVideoUrl } from "../api";

export default {
  name: "app",
  data() {
    return {
      videoSource: [
        {
          src: "https://app.burket.cn/api/Index/wxv?id=wxv_2379200322062876672",
        },
      ],
      cover: "",
      title: "",
      article: "",
      playnum: "",
      avator: "",
      videolist: [],
    };
  },
  methods: {
    downloadClick() {
      alert('ۇنحاتتىڭ ىشىندە باسقا تۇسىرۋگە كەلمەيتىن سەبەپتى،وڭ جاق جوعارداعى ۇش نۇكتەنى باسىپ 在浏览器打开 دەگەندى باسىپ باسقا تور شولۋدان اشىپ تۇسىرەسىز\n 因无法在微信中下载文件，请点击右上角三个点，点击 在浏览器打开 进行下载 ');
    },

    homeClick: function () {
      this.$router.push({
        path: "/",
      });
    },
    numClick: function (index) {
      var that = this;
      if (this.videolist[index].vip != 0) {
        this.title = "《 " + this.videolist[index].name + " 》" + "بۇل سەرياسىن بۇركىت دەتالىن تۇسىرگەندە قانا كورە الاسىز";
        getRedictVideoUrl({ id: 'wxv_2379200322062876672' }).then(function (res) {
          that.videoSource = res.data;
        });
      } else {
        this.title = "《 " + this.videolist[index].name + " 》";
        const url = new URL(this.videolist[index].url);
        const searchParams = new URLSearchParams(url.search);
        const urlid = searchParams.get('id');
        getRedictVideoUrl({ id: urlid }).then(function (res) {
          that.videoSource = res.data;
        });
      }
    },
  },
  created: function () {
    var that = this;
    const vid = this.$route.query.id;
    getAll({ id: vid }).then(function (ret) {
      that.cover = ret.data.info.img;
      that.article = ret.data.info.remark;
      that.playnum = ret.data.info.playnum;
      that.avator = ret.data.info.img;
      that.videolist = ret.data.list;
      if (ret.data.list[0].vip != 0) {
        that.title = "《 " + ret.data.list[0].name + " 》" + "بۇل سەرياسىن بۇركىت دەتالىن تۇسىرگەندە قانا كورە الاسىز";
        getRedictVideoUrl({ id: 'wxv_2379200322062876672' }).then(function (res) {
          that.videoSource = res.data;
        });
      } else {
        that.title = "《 " + ret.data.list[0].name + " 》";
        const url = new URL(ret.data.list[0].url);
        const searchParams = new URLSearchParams(url.search);
        const urlid = searchParams.get('id');
        getRedictVideoUrl({ id: urlid }).then(function (res) {
          that.videoSource = res.data;
        });
      }
    });
  },
};
</script>

<style>
.container {
  background: linear-gradient(to bottom, #000000, #434343);
  /* 黑色渐变背景 */
  color: white;
  /* 字体颜色为白色 */
  padding: 20px;
}

.title {
  margin-top: 20px;
}

.wide-button {
  width: 250px;
  min-width: 250px;
  font-size: 14px;
}

.numberlist {
  margin-top: 15px;
}

.article {
  padding-top: 15px;
  padding-bottom: 15px;
  clear: both;
  text-align: justify;
}

.remark {
  font-size: 12px;
}

.avator {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 120px;
  height: 150px;
}

#app {
  margin-top: 20px;
}

.videonum {
  text-align: center;
  border-radius: 5px;
  background: #333333;
  width: 20px;
  height: 20px;
  margin: 5px;
  padding: 15px;
  list-style: none;
  float: left;
}

.logobox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: left;
}

.appname {
  margin-left: 8px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  /* 字体颜色为白色 */
}

.logo {
  width: 40px;
  /* 调整 Logo 的大小 */
  height: auto;
  /* 自动保持宽高比 */
}


/* 横幅图片容器 */
.banner-container {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  /* 撑满屏幕宽度 */
}

/* 手机版横幅 */
.mobile-banner {
  width: 21%;
  text-align: center;
}

.tv-banner {
  width: 79%;
  text-align: center;
}

.banner-img {
  width: 100%;
  border-radius: 10px;
}

.banner-name {
  margin-top: 5px;
  font-size: 14px;
  color: #ffcc00;
  /* 横幅名称颜色 */
}

/* 底部描述和公司名称 */
.footer-text {
  margin-top: 15px;
  font-size: 14px;
  color: #999;
  text-align: center;
  direction: ltr;

}

.company-name {
  display: block;
  margin-top: 15px;
  font-size: 14px;
  color: #777;
  font-weight: bold;
}


.icp-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 12px;
}

.icp-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
</style>
